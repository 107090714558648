import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import AdvantagesCard from "../../components/advantage-card";

const AdvantagesSection = ({ data }) => {
    return (
        <section className="advantage-section section-space-pt">
            <div className="container-full section-space-pb border-b">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title.heading}
                        {...data.section_title}
                    />
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-10 mt-10">
                    {data?.items &&
                        data?.items?.map((item) => (
                            <AdvantagesCard
                                key={item.id}
                                images={item.images}
                                title={item.title}
                                description={item.description}
                            />
                        ))}
                </div>
            </div>
        </section>
    );
};

AdvantagesSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default AdvantagesSection;
