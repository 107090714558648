import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TestimonialCard = ({ description, images, name, designation }) => {
    return (
        <div className="testimonial-wrap">
            {description && (
                <div className="testimonial-text mb-8 lg:mb-16">
                    <p className="text-[22px] md:text-[28px] lg:text-[35px] font-bold text-black leading-[1.305]">
                        {description}
                    </p>
                </div>
            )}
            <div className="testimonial-author flex items-center">
                <div className="testimonial-author-thumb shrink-0">
                    <GatsbyImage
                        image={getImage(images[0]?.src)}
                        alt={images[0]?.alt || name}
                    />
                </div>
                <div className="testimonial-author-content ml-6">
                    {name && (
                        <h5 className="testimonial-author-name">{name}</h5>
                    )}
                    {designation && (
                        <span className="testimonial-author-designation">
                            {designation}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

TestimonialCard.propTypes = {
    description: PropTypes.string,
    name: PropTypes.string,
    designation: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
};

export default TestimonialCard;
