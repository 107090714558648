import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogCard = ({
    title,
    slug,
    image,
    date,
    categories,
    author,
    excerpt,
}) => {
    const style = {
        metaStyle: `relative after:last:sr-only after:absolute after:w-[1px] after:h-4 after:ml-[10px] after:top-1/2 after:-translate-y-1/2 after:content-[''] after:bg-[#cccc]`,
    };
    return (
        <div className="blog-post-card group mb-10">
            <div className="blog-post-card__image">
                <Link
                    to={`/${slug}`}
                    className="block relative overflow-hidden bg-primary"
                >
                    {image ? (
                        <GatsbyImage
                            className="transition delay-300 w-full ease-in-out group-hover:opacity-20"
                            image={getImage(image?.src)}
                            alt={image?.alt || title}
                        />
                    ) : (
                        <StaticImage
                            className="transition border delay-300 w-full ease-in-out group-hover:scale-110 group-hover:opacity-20"
                            src="../../assets/images/placeholder-image.png"
                            alt={"Blog Post Image" || title}
                        />
                    )}
                </Link>
            </div>
            <div className="post-content mt-6">
                <ul className="blog-meta blog-meta-separator flex gap-5 items-center text-sm mb-4">
                    <li className={`${style.metaStyle}`}>
                        {categories.slice(0, 1).map((cat) => (
                            <span key={cat.slug} className="text-black">
                                {cat.title}
                            </span>
                        ))}
                    </li>
                    <li className={`${style.metaStyle}`}>{date}</li>
                </ul>

                {title && (
                    <h3 className="blog-title capitalize text-xl md:text-2xl mb-3">
                        <Link to={`/${slug}`}>{title}</Link>
                    </h3>
                )}

                {excerpt && <p className="mb-7">{excerpt}</p>}

                <ul className="blog-meta flex gap-5 items-center text-sm">
                    <li className="flex items-center gap-2">
                        <i className="fi fi-user text-2xl leading-none"></i>By
                        <Link to={`/${slug}`} className="text-black">
                            {author}
                        </Link>
                    </li>
                    <li className="flex items-center gap-2">
                        <i className="fi fi-pulse-rate text-2xl leading-none "></i>{" "}
                        155 Views
                    </li>
                </ul>
            </div>
        </div>
    );
};

BlogCard.propTypes = {
    title: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    slug: PropTypes.string,
    dateSlug: PropTypes.string,
    excerpt: PropTypes.string,
    image: PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
            .isRequired,
        alt: PropTypes.string,
    }),
    categories: PropTypes.array,
};

export default BlogCard;
