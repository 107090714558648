/* eslint-disable max-len */
import React, { Children } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";

const FloorPlanTabArea = ({ children }) => {
    const childArr = Children.toArray(children);
    return (
        <section className="floor-plan-section section-space-pt bg-whisper">
            <div className="container-full">
                <Tabs>
                    <TabList className="floor-plan-nav flex flex-wrap gap-1 mb-14">
                        {childArr?.map((item) => (
                            <Tab
                                key={item.key}
                                className="grow basis-0 relative"
                            >
                                {item?.props?.data?.section_title?.heading && (
                                    <button
                                        type="button"
                                        className="cursor-pointer whitespace-nowrap text-black text-[18px] md:text-[22px] lg:text-[24px] xl:text-[26px] 2xl:text-[30px] font-bold relative flex justify-center w-full p-[15px_15px] lg:p-[30px_15px] bg-[#E9E9F0]"
                                        dangerouslySetInnerHTML={{
                                            __html: item.props.data
                                                .section_title.heading,
                                        }}
                                    />
                                )}
                                <span className="before:opacity-0 before:absolute before:bottom-0 before:left-1/2 before:w-6 before:h-6 lg:before:w-8 lg:before:h-8 before:content-[''] before:-translate-x-1/2 before:rotate-45 before:bg-white" />
                            </Tab>
                        ))}
                    </TabList>
                    <div className="tab-content">
                        {childArr?.map((item) => (
                            <TabPanel key={item.key}>{item}</TabPanel>
                        ))}
                    </div>
                </Tabs>
            </div>
        </section>
    );
};
FloorPlanTabArea.propTypes = {
    // data: PropTypes.shape({
    //     section_title: PropTypes.shape(SectionTitleType),
    // }),
    children: PropTypes.node.isRequired,
};
export default FloorPlanTabArea;
