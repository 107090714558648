import React from "react";
import PropTypes from "prop-types";
import HeroContent from "../../../components/hero";
import SwiperSlider, { SwiperSlide } from "@components/shared/swiper";

import { StaticImage } from "gatsby-plugin-image";

const HeroSection = ({ data }) => {
    return (
        <section className="hero-slider-section relative">
            <SwiperSlider
                className="hero-section relative group"
                options={{
                    autoplay: false,
                    slidesPerView: 1,
                    navigation: false,
                    pagination: false,
                    spaceBetween: 0,
                    loop: false,
                    centeredSlides: false,
                }}
                navPosition={2}
            >
                {data?.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <HeroContent
                            heading={item.headings}
                            bgImage={item.images}
                            buttons={item.buttons}
                        />
                    </SwiperSlide>
                ))}
            </SwiperSlider>
            <button
                className="hero-scroll-down absolute z-10 bottom-[-5px] left-1/2 -translate-x-1/2 flex items-center
                 justify-center w-[118px] h-[33px] text-black"
            >
                <i className="absolute z-10 fi fi-down-arrow leading-none text-base"></i>
                <StaticImage
                    className="b-0 absolute b-0"
                    src="../../../data/images/hero/hero-scroll-down.png"
                    alt=""
                />
            </button>
        </section>
    );
};
//
HeroSection.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default HeroSection;
