import React from "react";
import PropTypes from "prop-types";
import FunFact from "../../../components/funfact/layout-01";

const AboutUsSection = ({ data }) => {
    return (
        <section className="about-us-section">
            <div className="container-full">
                <div className="section-space-ptb border-b grid grid-cols-1 md:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    <div className="sidebar-text mb-10">
                        <div className="sidebar-text-item mb-10">
                            <h6 className="font-normal text-primary text-sm uppercase mb-0">
                                begining from
                            </h6>
                            <h5 className="text-base">2016</h5>
                        </div>
                        <div className="sidebar-text-item">
                            <h6 className="font-normal text-primary text-sm uppercase mb-0">
                                completed in
                            </h6>
                            <h5 className="text-base">2021</h5>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="content-block mb-[60px]">
                            {data?.headings[0] && (
                                <h2 className="mb-5 md:mb-10">
                                    {data?.headings[0]?.content}
                                </h2>
                            )}
                            {data?.headings[1] && (
                                <h5 className="text-base lg:text-[20px]">
                                    <i className="fi fi-maps-and-flags mr-6 " />{" "}
                                    {data?.headings[1]?.content}
                                </h5>
                            )}
                        </div>
                        <div className="grid grid-cols-1 xs:grid-cols-2 gap-x-8 lg:gap-x-20 mb-[-40px]">
                            {data?.items &&
                                data?.items.map((item) => (
                                    <FunFact
                                        key={item.id}
                                        countNumber={item.countNumber}
                                        title={item.title}
                                        icon={item.icon}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

AboutUsSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default AboutUsSection;
