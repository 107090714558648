import React from "react";
import PropTypes from "prop-types";

const FeaturesCard = ({ title, icon, description }) => {
    const style = {
        iconStyle: `relative z-[1] flex overflow-hidden items-center text-[40px]
        justify-center w-[85px] h-[85px] shrink-0 rounded-full text-[#3F3C3C] bg-[#F3F3F9] mr-4 lg:mr-6 xl:mr-9 delay-500`,
        iconStyleBefore: `before:h-full before:w-full group-hover:text-white 
        before:opacity-0 group-hover:before:opacity-100 before:border-full before:bg-primary 
        before:absolute before:-z-[1] before:top-0 before:right-0 before:bottom-0 before:left:0 content-[""]
         before:transition before:delay-500 before:duration-300 before:ease-in-out`,
        heading: `counterup text-primary font-bold leading-none`,
    };
    return (
        <div className="feature flex group items-center pt-10">
            {icon && (
                <div
                    className={`feature-icon ${style.iconStyle} ${style.iconStyleBefore}`}
                >
                    <i className={`leading-[0] ${icon}`}></i>
                </div>
            )}
            <div className="feature-content ">
                <h3 className={`${style.heading}`}>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

FeaturesCard.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
};

export default FeaturesCard;
