import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../shared/button";

const HeroContent = ({ heading, bgImage, buttons }) => {
    return (
        <div className="relative z-[1] flex items-center h-[560px] md:h-[670px] lg:h-[800px] xl:h-[900px] 2xl:h-[1150px] p-[120px_0_100px]">
            <span className="absolute -z-[1] top-0 right-0 left-0 w-full h-full bg-black/40 "></span>

            <div className="container-full">
                <div className="hero-content mt-10">
                    {heading[0] && (
                        <h3 className="hero-slide-sub-title mb-4 lg:mb-7 text-white">
                            {heading[0].content}
                        </h3>
                    )}
                    {heading[1] && (
                        <h1
                            className="hero-slide-title text-white sm:-ml-2 lg:-ml-3 sm:text-[70px] sm:leading-[70px] md:text-[90px] md:leading-[90px] text-[50px] lg:text-[150px]  lg:leading-[140px] xl:text-[180px] xl:leading-[160px] 2xl:text-[200px] 2xl:leading-[180px] uppercase"
                            dangerouslySetInnerHTML={{
                                __html: heading[1].content,
                            }}
                        />
                    )}
                    {buttons && (
                        <div className="button-group mt-8 lg:mt-12 space-x-4 hero-slide-btn">
                            {buttons.map(({ id, content, ...props }) => (
                                <Button
                                    key={id}
                                    {...props}
                                    className="items-center inline-flex"
                                >
                                    <i className="fi fi-model text-[30px] leading-none mr-3"></i>{" "}
                                    {content}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="hero-slide-bg absolute -z-[2] top-0 right-0 bottom-0 left-0 transition-[all_6s_linear]">
                <GatsbyImage
                    className="w-full h-full cover object-cover"
                    image={getImage(bgImage[0].src)}
                    alt=""
                />
            </div>
        </div>
    );
};

HeroContent.propTypes = {
    heading: PropTypes.arrayOf(
        PropTypes.shape({
            level: PropTypes.string,
            content: PropTypes.string,
        })
    ),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string,
        })
    ),
    bgImage: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
                .isRequired,
            alt: PropTypes.string,
        })
    ),
};

export default HeroContent;
