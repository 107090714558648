import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Logo from "../../../components/logo";
import MainMenu from "../../../components/menu/main-menu";
import MobileNavMenu from "../../../components/menu/mobile-menu";
import Button from "../../../components/shared/button";
import { useSticky } from "../../../hooks";

const Header = ({ data }) => {
    // Sticky Header
    const { sticky, headerRef, fixedRef } = useSticky();

    // OfCanvas Menu
    const [ofcanvasOpen, setOfcanvasOpen] = useState(false);

    // OfCanvas Menu Open & Remove
    const ofcanvasHandaler = () => {
        setOfcanvasOpen((prev) => !prev);
    };
    return (
        <header ref={headerRef}>
            <div
                ref={fixedRef}
                className={`bg-transparent absolute w-full mx-auto z-40 header-top  ${
                    sticky ? "sticky-header !fixed !top-0 bg-black w-full" : ""
                }`}
            >
                <div className="container-full">
                    <nav className="bg-transparent flex justify-between items-center">
                        <div className="flex items-center">
                            <div className="lg:mr-[80px] xl:mr-[180px]">
                                <Logo />
                            </div>
                            <MainMenu allmenuData={data?.menu} />
                        </div>
                        <div className="header-right-action flex items-center">
                            <Button
                                path="/contact-us"
                                size="sm"
                                color="transparent"
                                hover="hoverPrimary"
                                outline="light"
                                className=" hidden sm:block"
                            >
                                schedule a visit
                            </Button>
                            <button
                                onClick={ofcanvasHandaler}
                                onKeyDown={ofcanvasHandaler}
                                className="flex flex-col space-y-1.5 ml-8 lg:hidden"
                            >
                                <span className="line h-0.5 w-6 inline-block bg-white"></span>
                                <span className="line h-0.5 w-6 inline-block bg-white"></span>
                                <span className="line h-0.5 w-6 inline-block bg-white"></span>
                            </button>
                            <MobileNavMenu
                                MobilemenuData={data?.menu}
                                ofcanvasOpen={ofcanvasOpen}
                                ofcanvasHandaler={ofcanvasHandaler}
                            />
                        </div>
                    </nav>
                    <span className="inner-border border-b block"></span>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    data: PropTypes.shape({
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default Header;
