import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";
import TestimonialCard from "../../../components/testimonial/layout-01";
import SwiperSlider, { SwiperSlide } from "@components/shared/swiper";

const TestimonialSection = ({ data }) => {
    return (
        <section className="testimonial-section section-space-pt  section-space-pb-90">
            <div className="container-full">
                <div className="grid grid-cols-1 md:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    {data?.section_title && (
                        <SectionTitle
                            heading={data?.section_title?.heading}
                            {...data.section_title}
                        />
                    )}
                    <SwiperSlider
                        className="testimonial-slider relative group"
                        options={{
                            autoplay: false,
                            slidesPerView: 1,
                            navigation: false,
                            pagination: true,
                            spaceBetween: 0,
                            loop: false,
                            centeredSlides: false,
                        }}
                        navPosition={2}
                    >
                        {data?.items?.map((item) => (
                            <SwiperSlide key={item.id}>
                                <TestimonialCard
                                    description={item?.description}
                                    images={item?.images}
                                    name={item?.name}
                                    designation={item?.designation}
                                />
                            </SwiperSlide>
                        ))}
                    </SwiperSlider>
                </div>
            </div>
        </section>
    );
};

TestimonialSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default TestimonialSection;
