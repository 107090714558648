import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import FeaturesCard from "../../components/features-card";
const FeatureSection = ({ data }) => {
    return (
        <section className="features-section section-space-ptb">
            <div className="container-full">
                <div className="grid grid-cols-1 lg:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    {data?.section_title && (
                        <SectionTitle
                            heading={data?.section_title?.heading}
                            {...data.section_title}
                        />
                    )}
                    <div className="features-list">
                        {data?.headings[0] && (
                            <h2 className="mb-6">
                                {data?.headings[0]?.content}
                            </h2>
                        )}
                        {data?.items && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 2xl:gap-x-20">
                                {data?.items.map((item) => (
                                    <FeaturesCard
                                        key={item.id}
                                        title={item.title}
                                        icon={item.icon}
                                        description={item.description}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

FeatureSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default FeatureSection;
