import React, { useState } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MapLocationListSection = ({ data }) => {
    // eslint-disable-next-line react/prop-types
    const [activeId, setActiveId] = useState(data.items[0].id);

    return (
        <section className="map-location-list-section section-space-pt">
            <div className="container-full">
                <div className="grid items-center grid-cols-1 md:grid-cols-12">
                    <div className="md:col-span-5">
                        <div className="section-title mb-10">
                            <span className="sub-title text-body uppercase">
                                Ecostate’S NEIGHBORHOODS
                            </span>
                        </div>
                        <ul className="map-location-list">
                            {data?.items?.map((item) => (
                                <li
                                    key={item?.id}
                                    className={`map-location-item ${
                                        activeId === item.id ? "active" : ""
                                    } relative flex mb-11 last:mb-0 cursor-pointer bg-transparent items-center gap-5 lg:gap-x-10`}
                                    onClick={() => setActiveId(item.id)}
                                    role="presentation"
                                >
                                    <div
                                        className="icon relative shrink-0 w-[30px] h-[30px] mt-[7px] 
                                    border border-transparent rounded-full"
                                    >
                                        <span
                                            className="before:-translate-x-1/2 before:-translate-y-1/2 before:bg-[#CCCCCC] before:rounded-full before:absolute 
                                        before:top-1/2 before:left-1/2 before:w-[6px] before:h-[6px] before:content-['']"
                                        />
                                    </div>
                                    <div className="content">
                                        <h3 className="title text-xl lg:text-[26px] ">
                                            {item.title}
                                        </h3>
                                        <p className="address">
                                            {item.description}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="md:col-span-7">
                        <div className="map-location-layer relative">
                            <GatsbyImage
                                className="w-full cover object-cover"
                                image={getImage(data?.images[0].src)}
                                alt={data?.images[0].alt}
                            />
                            {data?.items?.map((item) => (
                                <div
                                    key={item.id}
                                    className={`absolute w-3 h-3 cursor-pointer rounded-full bg-primary shadow-[0_0_0_10px_rgb(14_164_120_/_10%)] ${
                                        activeId === item.id ? "active" : ""
                                    } ${item.id}`}
                                    onClick={() => setActiveId(item.id)}
                                    role="presentation"
                                >
                                    <span
                                        className="title opacity-0 before:absolute before:bottom-[-8px] before:left-1/2 
                                        before:w-4 before:h-4 before:content-[''] before:-z-[1] before:-translate-x-1/2
                                        before:rotate-45 before:bg-white
                                     shadow-[0_10px_40px_0_rgb(0_0_0_/_15%)] text-black rounded bg-white p-[5px_15px] left-1/2 -translate-x-1/2 whitespace-nowrap text-sm font-semibold top-[-70px] absolute "
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                            <div className="text-center absolute bottom-0 text-[#064DF0] w-full">
                                <a
                                    className="google-map-link"
                                    href={data?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fi fi-map-1 mr-2"></i>OPEN
                                    GOOGLE MAP
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

MapLocationListSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        link: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default MapLocationListSection;
