import React from "react";
import PropTypes from "prop-types";
import BlogCard from "../../components/blog-card";
import SectionTitle from "../../components/section-title";

const BlogSection = ({ data }) => {
    return (
        <section className="advantage-section section-space-ptb">
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title.heading}
                        {...data.section_title}
                    />
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 mt-10">
                    {data.items &&
                        data.items.map((item) => (
                            <BlogCard
                                key={`blog-post-${item.title}`}
                                title={item.title}
                                author={item.author}
                                date={item.postedAt.date}
                                dateSlug={item.postedAt.slug}
                                slug={item.slug}
                                image={item.image}
                                categories={item.categories}
                                excerpt={item.excerpt}
                            />
                        ))}
                </div>
            </div>
        </section>
    );
};

BlogSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default BlogSection;
