import React from "react";
import PropTypes from "prop-types";
import bgImage from "../playground/image/playground-bg.jpg";

const PlaygroundSection = (props) => {
    return (
        <section
            className="playground-section relative z-[1] p-[30%_0_60px] md:p-[30%_0_100px] bg-fixed bg-cover"
            style={{
                backgroundImage: `url(${bgImage})`,
            }}
        >
            <span className="before:absolute before:top-0 before:left-0 before:w-full before:h-full before:content-[''] before:opacity-10 before:bg-black before:-z-[1]" />
            <div className="container-full">
                <h2 className="playground-title text-white sm:text-[60px] md:text-[120px] xl:text-[150px] leading-[0.867]">
                    Public
                    <br />
                    Playground
                </h2>
            </div>
        </section>
    );
};

PlaygroundSection.propTypes = {};

export default PlaygroundSection;
